import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";


@Component({
  selector: "app-form-button",
  templateUrl: './form-button.component.html',
  styles: []
})
export class FormButtonComponent implements OnInit {

  @Output() click = new EventEmitter();

  private _enabled: boolean = true;
  private _nomargin: boolean = true;
  public nomargin_class = "";
  private _width: string = "28";
  private _height: string = "22";

  private _istoggle: boolean
  private _toggle: boolean
  public toggle_prefix: string = "";

  public btn_type = "primary";
  public btn_width: string = "28";
  public btn_height: string = "22";
  public btn_text: string;
  public btn_tooltip: string;
  public btn_icon: string;
  public img_float: string = "center";

  constructor() {
  }

  ngOnInit() {
  }

  get type(): string { return this.btn_type; }
  @Input() set type(value: string) { this.btn_type = value; }

  get nomargin(): boolean { return this._nomargin; }
  @Input() set nomargin(value: boolean) {
    this._nomargin = value;
    this.nomargin_class = value ? "no-margin-button" : "";
  }

  get enabled(): boolean { return this._enabled; }
  @Input() set enabled(value: boolean) { this._enabled = value; }

  get width(): string { return this._width; }
  @Input() set width(value: string) {
    this._width = value;
    if (value == "0" || value == "")
      this.btn_width = "auto";
    else
      this.btn_width = value + "px";
  }

  get height(): string { return this._height; }
  @Input() set height(value: string) {
    this._height = value;
    if (value == "0" || value == "")
      this.btn_height = "auto";
    else
      this.btn_height = value + "px";
  }

  get text(): string { return this.btn_text; }
  @Input() set text(value: string) {
    this.btn_text = value;
    this.img_float = this.btn_text ? "left" : "center";
  }

  get icon(): string { return this.btn_icon; }
  @Input('icon') set icon(value: string) { this.btn_icon = value; }

  get tooltip(): string { return this.btn_tooltip; }
  @Input() set tooltip(value: string) { this.btn_tooltip = value.replace(/_/g, " "); }


  get istoggle(): boolean { return this._istoggle; }
  @Input() set istoggle(value: boolean) { this._istoggle = value; }

  get toggle(): boolean { return this._toggle; }
  @Input() set toggle(value: boolean) {
    this._toggle = value;
    if (this._istoggle) {
      this.btn_icon = this.toggle ? "stop-circle" : "play-circle";
      this.btn_type = this.toggle ? "ok" : "info";
      this.toggle_prefix = this.toggle ? "Stop " : "Start ";
    }
  }

  onClicked(event) {
    event.stopPropagation();
    if (this._enabled)
      if (this._istoggle)
        this.click.emit(this.toggle);
      else
        this.click.emit(event);
  }
}